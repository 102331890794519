const config = {
	development: {
		API_BASE: "https://api.devnew.dokiv.se/v1",
		APP_URL: "https://app.devnew.dokiv.se",
		PORTAL_URL: "https://portal.devnew.dokiv.se",
		APP_ID: "3f49d195-e061-49ea-9ba8-987ded37c9e9"
	},
	production: {
		API_BASE: "https://api.devnew.dokiv.se/v1",
		APP_URL: "https://app.devnew.dokiv.se",
		PORTAL_URL: "https://portal.devnew.dokiv.se",
		APP_ID: "3f49d195-e061-49ea-9ba8-987ded37c9e9"
	}
};

const environment = process.env.NODE_ENV || 'development';
console.log('Environment:', environment);

module.exports = config[environment];